<template>
  <v-container>
    <!-- Start Error Dialog -->
      <v-dialog v-model="errorDialog" max-width="500px">
      <v-card>
        <v-alert
        :type="type"
        prominent
        border="left"
        dismissable
        close-text="Close Alert"
        class="ma-0"
      >
        <v-row align="center">
          <v-col class="grow">
            {{errorMessage}}
          </v-col>
          <v-col class="shrink">
            <!-- <v-btn>Take action</v-btn> -->
            <v-btn color="white" text @click="errorDialog = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-col>
        </v-row>
        </v-alert>
      </v-card>
    </v-dialog>
    <!-- End Error Dialog -->
    <v-row class="top-offset" justify="center" align="center">
        <v-col cols="12" lg="12" md="12" sm="12" justify="center" align="center">
            <v-data-table
                :headers="headers"
                :items="report"
                item-key=""
                sort-by="category"
                class="elevation-1 border"
                :items-per-page="-1"
                :loading="loading"
                loading-text="Loading... Please wait">
            </v-data-table>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

  export default {
    data () {
      return {
        headers: [
          {
            text: 'Brand',
            align: 'start',
            value: 'name',
            groupable: false,
          },
          { text: 'New', value: 'new' },
          { text: 'Used', value: 'used'},
          { text: 'Invalidated', value: 'invalidated'},
        ],
        report: [],
        loading: true,
        errorDialog: false,
        errorMessage: "",
        type: "error"
      }
    },
    methods: {
      init(){

      },
      async cardReport(){
        axios
          .post(this.$apiUrl, {
            query: `query {
                  cardReport{
                      used
                      new
                      invalidated
                      name
                  }
              }`
          },{
          headers: {
              Authorization: "Bearer " + await this.$auth.getAccessToken(),
          }
        })
        .then((response) => {
          if(response.data.errors == undefined) {
            console.log(response.data.data.cardReport);
            this.report = response.data.data.cardReport;
            this.loading = false;
          }else {
            console.log(response.data.errors, "errors");
            this.errorMessage = response.data.errors[0].message;
            this.errorDialog = true;
            this.loading = false;
          }
        });
      }
    },
    created(){
        this.cardReport();
    },
  }
</script>

<style scoped>
.top-offset {
    margin-top: 100px !important;
}
.border{
    border: 1px solid #007bff;
}
</style>
